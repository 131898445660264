$selected-bg: #444755;
$bg-hover: #E3E4E8;

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $selected-bg !important;
  border: solid 2px $selected-bg !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='red' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: $selected-bg !important;
}

.custom-checkbox .custom-control-input:hover ~ .custom-control-label::before {
  $shadow-effect: none !important;

  -webkit-box-shadow: $shadow-effect;
  -moz-box-shadow: $shadow-effect;
  box-shadow: $shadow-effect;
  background-color: $bg-hover ;
}

.custom-checkbox .custom-control-input ~ .custom-control-label::before {
  border: solid 2px $selected-bg;
}

/** focus shadow pinkish **/
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  box-shadow: none;
  border: solid 2px $selected-bg !important;
}

.custom-control-label {
  min-width: 100% !important;
}

.privacy-links {
  color: #333 !important;
  text-decoration: underline;

  &:hover {
    color: #333 !important;
  }
}

.custom-checkbox > label {
  padding-left: 5px !important;
  display: inline-block !important;
  height: auto !important;
}


.custom-checkbox__error-message {
  color: #DB5446;
  font-size: 14px;
  padding-left: 20px;

  & img {
    margin-right: 4px;
  }

  &.hidden {
    display: none;
  }
}