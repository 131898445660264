.menu {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 900;
  overflow: hidden;
  display: block;
  transition: all ease 0.5s;

  &--hidden {
    display: none;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0 !important;
    width: 100vw;
    height: 100vh;
    background-color: #000000bd;
  }

  &__list {
    width: 70%;
    max-width: 490px;
    min-width: 300px;
    height: 100vh;
    background-color: #fff;
    top: 0;
    right: 0;
    position: fixed;
  }

  &__head {
    width: 100%;
    height: 10vh;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10% 0 40%;
  }

  &__title {
    font-family: Lato;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
  }

  &__close-button {
    color: #fff;
    cursor: pointer;
    padding: 10px;
  }

  &__item-list {
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
    height: 75; /* for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 83);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    height: 7vh;

    &-clear-button {
      width: 50%;
      text-align: center;
      text-transform: capitalize;

      & span {
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 1px;
        text-align: center;
        color: #888888;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &-apply-button {
      width: 50%;
      text-align: center;
      text-transform: capitalize;
    }
  }
}
