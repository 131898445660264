.service-price {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #E4022D;

  @media screen and (max-width: 800px) {
    line-height: 24px;
    font-size: 18px;
  }
}