.ap-detail {
  &__wrapper {
    background-color: rgba(238, 238, 238, 0.4);
    padding: 16px;
    max-width: 647px;
    border-radius: 10px;
    margin: auto;
  }

  &__title {
    font-weight: 590;
    font-size: 20px;
    line-height: 28px;
  }

  &__body {
    padding: 0 80px;
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px 4%;

    @media screen and (max-width: 650px) {
      flex-direction: column;
      align-items: center;
      padding: 0 10px;
      gap: 10px 4%;
    }

    @media screen and (max-width: 350px) {
      padding: 0;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    min-width: 45%;
    max-width: 45%;

    @media screen and (max-width: 650px) {
      min-width: 80%;
      max-width: 80%;
    }

    @media screen and (max-width: 350px) {
      min-width: 100%;
      max-width: 100%;
    }

    &-title {
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    &-value {
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      max-width: 100%;
      word-break: break-word;
    }
  }
}
