.inp-text {
  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #444755;
    position: absolute;
    background-color: #FFF;
    margin-left: 16px;
    margin-top: -10px;
    padding: 0 5px;
  }

  &__input {
    display: block;
    margin-top: 15px;
    border: solid 2px #1A1C23;
    border-radius: 8px;
    width: 100%;
    padding: 16px 12px 16px 16px;
    transition: all ease .2s;

    color: #495057;

    &--error {
      border-color: #DB5446;
    }

    &:hover, &:active, &:focus {
      box-shadow: none;
      outline: none;
      border-color: #5D606F;
    }
  }
}