.selectable-item {
    margin: 0;
    border-bottom: solid 1px #E8E8E8;

    &__container {
        margin-left: 5px !important;
        display: flex !important;
        padding: 10px;
    }

    &__checkbox {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
        margin-right: 20px !important;
        border: 0;
    }

    &__label {
        margin-left: -10px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 300;
        text-align: left;
        width: 100% !important;
        cursor: pointer;
        overflow-wrap: break-word !important;
        display: inline-block;

        & > span {
            max-width: 100%;
            overflow-wrap: break-word !important;
            display: inline-block;
        }

        &-icon {
            margin-right: 7px;
        }
    }

    &__check-icon {
        color: #90CA04;
        font-size: 18px;
    }
}
