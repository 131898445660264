body * {
  -webkit-tap-highlight-color: transparent;
}

header {
  border-top: 3px solid #aad500;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
header,
hgroup,
textarea,
input,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  vertical-align: baseline;
}

.button:focus {
  outline: none;
}

.btn-disabled {
  background-color: var(--gray-light) !important;
  border-color: gray !important;
}
.btn-disabled:hover {
  cursor: not-allowed !important;
}

#Sidebarbanner {
  width: 100%;
  margin-top: 5vh;
}

#detailService p {
  overflow-wrap: break-word;
}

.categoty-title-container {
  padding: 15px !important;
  border-bottom: 1px solid #f0f0f0;
  margin: 0 -15px;
}
.cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

#services-display {
  display: flex;
  flex-flow: column;
}

#paginator-top-pages {
  font-size: 0.8em;
  color: #9b9b9b;
  margin-right: 10px;
  text-transform: capitalize;
}

#paginator-bottom-pages {
  position: absolute;
  left: 5%;
  font-weight: bold;
}

.title {
  font-size: 16px;
}

a:hover {
  color: #888;
  text-decoration: underline;
}

.action-bar {
  /* padding: 20px 30px; */
  display: flex;
  background-color: #fff;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  margin: 0;
}

.action-bar-right {
  background-color: #fff;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}

.action-bar-left {
  padding: 20px 30px;
  display: flex;
  background-color: #fff;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 10px 10px 0;
}

.action-bar-left-lf {
  display: flex;
  background-color: #fff;
  -webkit-align-items: left;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 10px 10px 0;
}

.action-bar-margin {
  margin: 10px 0;
}

.filter-bar {
  background-color: #fff;
  min-height: 1577px;
  width: 100%;
}

.filter-bar-mobile {
  display: none;
}

.paginator-bar {
  max-width: 1000px;
  height: 92px;
  background-color: #fff;
  margin-left: 0px !important;
  justify-content: space-between;
  padding: 0 5%;
}

.paginator-bar-top {
  max-width: 1000px;
  height: auto;
  background-color: #fff;
  margin-left: 0px !important;
  justify-content: space-between;
  padding: 2% 1%;
}

.result-bar {
  /* display: -webkit-box !important; */
  /* background-color: #fff; */
  /* min-height: 1379px; */
  margin-top: 5px;
  margin-left: 0px !important;
}

.title-item-detail {
  border-bottom: 2px solid #aad500;
  margin-bottom: 10px;
  padding: 10px 10px 5px 10px;
}

.detail-service {
  padding: 10px 10px 5px 10px;
  text-align: justify;
}

.arrow-left-margin {
  margin-top: 3px;
}

.button.button-primary-large {
  height: 44px;
}
.button.button-primary {
  font-weight: 700;
  background-color: #8fca00;
  color: #fff;
  height: 36px;
  width: 80%;
  min-width: 60px;
  max-width: 220px;
}
.button {
  font-weight: 400;
  font-size: 12px;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  color: #888;
}
button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.dropdown-wrapper .dropdown-select button {
  padding-top: 15px;
}

.dropdown-wrapper button {
  width: 100%;
  min-width: 200px;
  text-align: left;
  padding: 5px 0px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bbbbbb !important;
  border-radius: 0px;
}

.dropdown-wrapper .dropdown-select {
  display: inline-block !important;
  border: 0 !important;
  border-radius: 0 !important;

  padding: 0 !important;
  margin: 0 !important;
  font: inherit !important;
  outline: none !important;
  line-height: 1.2 !important;
  background: transparent !important;
  min-width: 220px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  -webkit-appearance: none !important;
}

.dropdown-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-wrapper .dropdown-list {
  width: 250px;
}
.dropdown-wrapper .dropdown-list {
  background: #fff;
  box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
  display: block;
  padding: 8px 0;
  margin: 0;
  height: auto;
  position: absolute;
  top: calc(100% + 2px);
  width: 175px;
  right: 0;
  max-height: 331px;
  overflow-y: auto;
  white-space: initial;
  z-index: 9000;
}

.dropdown-toggle::after {
  display: none !important;
}

.tr-silver {
  background-color: #888888;
  color: #fff;
  text-align: center;
}

.squareActive {
  font-size: 10px;
  border: 1px solid #bbbbbb;
  padding: 15px 10px 15px 10px;
  border-color: #aad500;
}

.squareActive:active {
  background: #aad500;
  border-color: #aad500;
}

.squareActive:focus {
  background: #aad500;
  border-color: #aad500;
}

.squareInactive {
  font-size: 10px;
  border: 1px solid #888888;
  background-color: #888888;
  color: #fff;
  padding: 15px 10px 15px 10px;
}

.line1 {
  width: 83px;
  height: 38px;
  border-bottom: 1px solid white;
  -webkit-transform: translateY(-21px) translateX(4px) rotate(34deg);
  position: absolute;
  /* top: -20px; */
  margin-top: -6px;
  margin-left: -11px;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
  margin-left: 10px;
}

/**
*PAGINATION
**/

.pagination {
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.pagination .pages {
  font-size: 12px;
  color: #9b9b9b;
  line-height: 25px;
  margin-right: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

ol,
ul {
  list-style: none;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.selected {
  background-color: #aad500;
  color: #fff;
  border-radius: 50%;
}

li {
  margin: 5px;
}
li,
button {
  line-height: 25px;
  min-height: 25px;
  text-align: center;
  min-width: 25px;
  font-size: 14px;
  font-weight: 700;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
}

/* input[type="checkbox"] {
  display: none;
} */
input[type="checkbox"] + label {
  background: url("https://assets.v7-io.invisionapp.com/assets/A_MGFjZjlkZDY2YjhlM2JmOY0omPmv5aHV5V6jqhP9Nf70910v9MQF_cQY7pQt4UBJFBX4HeKZTTv2E7GlfKZn1sujgqAYrZoJbcrKBpvdZD9tdYsnWQQF5p0oMIh_2ZVTHZImCgBDChXcppOwtMVQTOA6evdqDvcPYdgjUPUh0NAlO70mCgt8-z3lVKteQxz7oHaIyvdBqe9ddKtvoR5v9MoVnjUjnswJMv5mXCBeVMA=");
  background-size: contain;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  padding: 0 0 0 0px;
  margin-right: 18px;
}

input[type="checkbox"]:checked + label {
  background: url("https://assets.v7-io.invisionapp.com/assets/A_MGFjZjlkZDY2YjhlM2JmObGRv7PNkcCkFtx7Ve7dcQJJSEn26hb0qfaeQ_dvMKtaqmkLwK7bbC5D1BUxsaj6e9gY9O1ySqihBmzDNV9EpxEEU2v37V3296j4tSv6D4_oOt87mfI_OV5j9H5N7WNVNwqKfWv3mTPUBH3bW61Lb-w_MDKn2HJZHpJwoglPCR3G6sREBX8i_XtOIxsIyX0QVmzuGD-J5GSSlKqWm2-W43k=");
  background-size: contain;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  padding: 0 0 0 0px;
  margin-right: 18px;
}

.checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 100%;
}

.multiselect {
  overflow-y: auto;
}

ol,
ul {
  list-style: none;
}

.checkbox .title {
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  cursor: pointer;
  padding: 2px 0;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.ellipses {
  font-weight: 700;
  margin: 5px 0;
  width: auto;
  min-width: auto;
  color: #888;
}

body * {
  -webkit-tap-highlight-color: transparent;
}
ol,
ul {
  list-style: none;
}

.primary {
  color: #333;
}

.primary-color,
.swal-button--confirm {
  color: #aad500;
}

.copy1 {
  font-size: 14px;
}

.copy3 {
  font-size: 14px;
}

.col-lg-4 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.col-4 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 30.8% !important;
}

.col-3-c {
  flex: 0 0 25%;
  max-width: 24.1% !important;
}

.col-lg-3 {
  flex: 0 0 25%;
  max-width: 24.1% !important;
}

.col-8-c {
  flex: 0 0 66.666667%;
  max-width: 65.79% !important;
}

h6 {
  font-size: 14px;
  line-height: normal;
  text-align: left;
  color: rgb(0, 0, 0);
  letter-spacing: -0.28px;
  font-style: normal;
  font-weight: 600;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.l1category {
  margin: 8px 0;
}

a {
  -webkit-text-decoration: none;
  text-decoration: none;
}

/**
STRUTURE
**/

.container {
  margin: 0 auto;
  max-width: 1281px !important;
  width: 100%;
  background-color: #F5F5F5;
  padding: 5px 0;
}

.step-container {
  min-height: 720px;
  background: #ffffff;
}

.step-container .breadcrumb {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  background-color: #ffffff;
  justify-content: flex-start;
  padding-right: 0px;
  padding-left: 0px;
}

.arrow-left .button.button-primary {
  color: #fff !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.step-container hr,
.container-confirmation-service hr {
  border: 1px solid #f8f8f8;
  margin-left: 35px;
  width: 90%;
}

.contact-form .form-control:valid {
  border: 1px solid #ced4da;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  padding: 0px;
}
.contact-form .form-control:invalid {
  border: 1px solid red;
}

.detail-category-tr {
  padding: 0 !important;
  width: 130px;
  color: #888888;
}

.detail-value-tr {
  color: #888888;
}

.detail-service {
  width: 529px;
  min-height: 390px;
  background: #f8f8f8;
}

.help-contact {
  position: relative;
  bottom: 0;
  width: 100%;
  display: block !important;
  padding-left: 65px;
  padding-bottom: 23px;
  padding-top: 15px;
}

.help-contact-mobile {
  position: relative;
  bottom: 0;
  width: 100%;
  display: block !important;
  padding-left: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
}

.contact-form .form-label {
  font-size: 14px;
}

/**
* ITEM
**/

.item-container {
  width: 100%;
}

.pod {
  background-color: #fff;
  width: 100%;
}

.pod-action {
  width: 100%;
  padding: 12px 15px 11px 15px;
}

.item-pod {
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-left: none;
  position: relative;
  min-height: 430px;
}

.item-pod-head {
  position: relative;
}

a.item-link {
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 496px;
  text-align: center;
}

a.item-link > img {
  width: 100%;
  -webkit-transition: -webkit-transform 0.5s ease-out,
    -webkit-transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}

.banner-container {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

img.service-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pod-title {
  margin-bottom: 4px;
}
.bold {
  font-weight: 700;
}

.secondary {
  color: #888;
}

/**
*FILTER
**/

.card-header {
  background-color: #fff !important;
  border-bottom: 0 !important;
  padding: 15px 0 !important;
}

.card-body {
  padding: 0 !important;
}

.card {
  border: 0 !important;
}

.card-button {
  width: 100%;
  text-align: left;
}

.card-button-select {
  display: none;
}
.card-button-select :checked ~ .card-button {
  color: red;
}

.arrow-down-right {
  float: right;
}

.title-filter {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  margin-left: 15px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 113%;
  margin-left: -16px;
}

.title-filter-item {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  margin-left: 15px;
}

.highlighted {
  font-weight: 700;
}

/**
* BUTTONS
**/
buttons {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.labelTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin: 0px;
  color: #333333;
}

.top-filter > div {
  flex-wrap: wrap;
}

.top-filter button {
  overflow: hidden;
  max-width: 35%;
}

.top-filter > div > div {
  margin: 0;
  padding: 15px 0;
  margin-left: 0 !important;
}

.professionals-form {
  padding-left: 0 !important;
}

.vSeparationTop {
  margin-top: 3%;
  padding-left: 0 !important;
}

.vSeparationTop > div {
  flex-wrap: wrap;
}

.labelSelector {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #9b9b9b;
  margin: 0px;
}

/* DELETE */
.productCode {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: right;
  display: inline-block;
  width: 100%;
  color: #888888;
}

/* DELETE */
.brandTitle {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}

.productTitle {
  font-family: Lato;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}

.categoryTitle {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #aad500;
  margin-top: 2%;
}

.descriptionTitle {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.btnReservar {
  margin-bottom: 10% !important;
  width: 100% !important;
  height: 50px !important;
}

.btnDisabled {
  background-color: var(--gray-light) !important;
  cursor: not-allowed !important;
}

.colContainer {
  padding: 3%;
}

.pictureContainer {
  height: 100%;
}

.ResponsiveHeader {
  max-width: 1235px;
  margin-bottom: 15px;
  height: 210px;
  margin: 15px 0;
}

.gridContainer {
  display: grid !important;
  grid-template-columns: 1.3fr 4fr;
  grid-gap: 20px;
}

.paginator-bottom {
  margin-top: 12px;
  margin-left: 0px !important;
  height: 89px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.loadInfoItemContainer {
  margin-bottom: 6%;
}

.adjustStore {
  max-width: 93.5%;
  margin-left: 10px;
}

.mobileGrid {
  margin-left: 10px;
}

.dropdown-item {
  width: 95% !important;
  margin: auto;
}

.filterMargin {
  margin: 0 !important;
  margin-right: -10px !important;
}

.grid {
  border: 1px solid #f0f0f0;
  background-color: #fff;
  height: 408px;
  display: inline-table;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.grid:hover {
  cursor: pointer;
}

/* Breadcrumb style */
.breadcrumb-items {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.breadcrumb-items + .breadcrumb-items::before {
  content: ">";
  margin-right: 4%;
}

.non-active-link a,
.breadcrumb-items + .breadcrumb-items::before {
  color: #bbbbbb;
  font-size: 12px;
}
.active-link {
  color: #333333;
  font-size: 12px;
}
.breadcrumb-container,
.breadcrumb-item {
  white-space: nowrap;
}

.breadcrumb-container {
  background-color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.filter-store-container {
  margin-top: 5%;
}

.profesional-title-container {
  margin-top: 10%;
}

.location-title-container,
.profesional-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-location,
.title-profesional {
  font-size: 16px;
  margin: 0;
  padding-left: 1%;
}

.dropdown-wrapper.dropdown-filter-store,
.dropdown-wrapper.dropdown-filter-profesional {
  width: 100%;
}
.dropdown-filter-profesional {
  margin-left: 0;
  margin-top: 10px;
}

.hiddenMobile {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}
.hiddenMobileContainer.hiddenMobileContainer,
.notShowTitle {
  display: block;
}

.hiddenDesktop.hiddenDesktop {
  display: none;
}

.hiddenDesktop-headers.hiddenDesktop-headers {
  display: none;
}

.flexColumn {
  display: flex;
  flex-direction: row;
}

.popup-class {
  height: 440px;
}

.confirm-button-class {
  height: 44px;
  font-weight: 700;
  background-color: #8fca00;
  color: #fff;
  min-width: 60px;
  max-width: 260px;
  outline: none;
  width: 100%;
  white-space: nowrap;
  font-size: 14px !important;
}

.images-carrusel {
  height: 140px;
  position: inherit;
}

.dropdownDetailService button {
  max-width: 100%;
}

.dropdown-menu.show {
  min-width: 100%;
}
.btn.btn:focus {
  box-shadow: none;
}

#service-calendar-btn {
  border: none;
}

#service-calendar-btn {
  background-color: #8fca00;
  color: #ffffff;
}

.btn:not(.btn-dropdown-select):not(.dropdown-toggle) {
  border-color: transparent !important;
}

#h3-name {
  word-break: break-word;
}
#item-description {
  overflow-wrap: break-word;
}

/* Confirmation Component */
.container-confirmation-service {
  background-color: #ffffff;
}
.confirmation-title {
  background-color: #ffff;
  padding: 0 2%;
  margin: 0 15px;
  font-size: 24px;
}

.info-container,
.footer-message-confirmation {
  background-color: #f8f8f8;
  /* margin: 40px; */
}

.image-description-confirmartion-container,
.contact-service-confirmation {
  display: flex;
}

.img-container {
  padding-left: 0;
  width: 100%;
}

.img-confirmation-service,
.info-confirmation-service-container {
  /* padding: 66px 66px 0 66px; */
  width: 100%;
}

.info-confirmation-service-container {
  padding-left: 0;
}

span.orders {
  color: #aad500;
}

/* .footer-message-confirmation{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
} */

.contact-service-confirmation {
  margin-bottom: 33px;
}

.goToOrders {
  display: none;
}

.footer-message-confirmation p {
  font-size: 18px;
  font-weight: bold;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.paginationnew {
  width: 12px;
}

.page-link {
  color: green;
  background-color: rgb(170, 213, 0);
  border-color: white;
  border-radius: 30px;
  margin-left: 4px;
  margin-right: 4px;
  width: 29px;
  height: 29px;
  padding: 0px;
}

.pagination pagination-sm {
  margin-bottom: 0px;
}

.etiquetasFalabella {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 1px;
}

.maxAlingItem {
  max-width: 12rem;
}

.iconoCheck {
  padding-top: 2px !important;
  padding-left: 6px !important;
}
.iconoUnCheck {
  width: 18px !important;
  padding: 0px !important;
}

.itemScroll {
  max-height: 245px;
  overflow-y: auto;
  margin-left: 10px;
}
.serviciosUndefined {
  background-color: white;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: 30rem;
}
#filter-store-form {
  padding-right: 2rem;
}

.itemScroll {
  max-height: 245px;
  overflow-y: auto;
}

.filterInputFilterForm {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.OptionsMenu {
  max-height: 300px;
  overflow-y: auto;
}

.buttonFilterSelected {
  font-size: 0.8rem;
  background-color: rgb(254, 255, 248);
  border: 1px solid rgb(143, 202, 0);
  border-radius: 4px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px 10px 10px 0px;
  padding: 4px 6px;
  cursor: pointer;
  font-family: inherit;
  outline: none;
}

.buttonDeselectFilter {
  color: rgb(187, 187, 187);
  font-size: 14px;
  font-weight: 700;
  margin-left: 8px;
  outline: none;
  display: flex;
}

.buttonDeselectFilter:hover {
  color: black;
}

.titleFilterSelected {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: #888;
  margin: 8px 0;

}

.buttonRemoveAllFilter > span > i {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.3rem;
}
.containerButtons {
  padding-top: 1rem;
  margin-left: 10px;
}
.contact-information {
  margin: 0 auto;
  max-width: 1281px !important;
  width: 100%;
  background-color: white !important;
  padding: 5px 0;
}

.aligItem {
  text-align: left;
}

.btn-white {
  max-height: 36px;
  color: #8fca00;
  background-color: #FFF;
}
.btn-white:hover {
  background-color: #8fca00;
  color: #FFF;
}

/* DELETE */
/* PRICE */
.price-item {
  width: 140px;
  min-height: 30px;
  max-height: 60px;
  text-align: center;
  background-color: #E4022D;
  padding: 3px;
}

.price-item__text {
  color: #FFF;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  letter-spacing: 0em;
}

.schedule-instructions {
  margin-bottom: 25px;
}

.schedule-instructions__text {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #333;
}

.confirmation-form__description-container {
  margin-top: 12px;
}

.confirmation-form__description-title {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}

.confirmation-form__description-content {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.confirmation-form__service-detail {
  margin-top: 24px;
}

.service-confirmation__custom-input-label {
  margin-bottom: 0 !important;
}

.service-confirmation__custom-input-text {
  border: 0 !important;
  border-bottom: solid 1px #c1c1c1 !important;
  border-radius: 0 !important;
}

.availability-button-xs {
  display: block !important;
}

.availability-button-xs > .btnReservar {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 900px) {
  .grid {
    height: 350px;
  }
}

@media screen and (max-width: 430px) {
  .grid {
    height: 270px;
  }
}

@media screen and (max-width: 767px) {
  .grid {
    height: 290px;
  }

  /* DELETE */
  .productCode {
    text-align: left;
  }

  .availability-button-md {
    display: none;
  }

  .loadInfoItemContainer-xs {
    padding: 0 !important;
  }

  .price-item {
    margin: 19px 0px;
  }
}

.cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}