.survey-textarea {
  background: #FFFFFF;
  border: 1px solid #2E3038;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  min-height: 100px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444755;
}