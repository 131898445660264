.col-content {
  grid-column: span 10 / span 10;
}

.service-calendar-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
}

.service-calendar-grid-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.selected-hour {
  background-color: var(--green);
  color: white;
}

.service-calendar-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  @media screen and (max-width: 1200px) {
    overflow: auto;
    padding: 5%;
  }

  .service-calendar-top {
    display: flex;
    justify-content: space-between;
    padding: 2vh 2vw;
    font-weight: bold;

    & span {
      font-family: 'Plus Jakarta Sans' !important;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
    }
  }

  .service-calendar-container {
    display: flex;
    flex-flow: column;
    width: 80vw;
    // max-height: 85vh;
    border-radius: 10px;
    background-color: white;
    min-width: 360px;

    .service-calendar-date {
      display: flex;
      flex-flow: column;
      height: 20%;
      margin: 1vh 0;

      .col-content {
        border-bottom: 1px solid var(--gray);
        padding: 2vh 0;
      }

      .service-calendar-date-title {
        font-weight: bold;
      }

      .service-calendar-week-container {
        svg {
          &:hover {
            transform: scale(1.2);
            cursor: pointer;
          }

          path {
            fill: var(--green);
          }
        }

        .service-calendar-date-week {
          font-weight: bold;
        }
      }
    }

    .service-calendar-hours {
      height: 55%;
      overflow-y: auto;

      .service-calendar-grid-week {
        > div > div {
          &:hover {
            @extend .selected-hour;
            cursor: pointer;
          }
          font-size: 0.8em;
          margin-right: 10px;
          margin-bottom: 10px;
          border: 1px solid var(--gray);
          padding: 20px;
        }
      }
    }

    .service-calendar-footer {
      padding: 2vh;
    }
  }
}

.service-calendar-modal-mobile {
  padding: 0;
  height: calc(100vh - calc(100vh - 100%));
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-height: 750px) {
    padding-top: 2%;
  }

  @media screen and (max-height: 700px) {
    padding-top: 0;
  }

  & > .service-calendar-container {
    z-index: 999;
    max-width: 723px;
    
    @media screen and (max-width: 900px) {
      min-width: 90%;
      max-height: 100vh;
    }

    @media screen and (max-width: 800px) {
      margin-top: auto;
      min-width: 100%;
      max-height: 100%;
      border-radius: 10px 10px 0px 0px;
    }
  }

  .service-calendar-top {
    border-bottom: solid 1.5px #e0e0e0 !important;
  }

  .modal-container {
    max-height: calc(var(--vh, 1vh) * 75);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-fixed-steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .modal-step__calendar {
      grid-area: 1 / 1 / 2 / 2;
    }

    .modal-step__blocks {
      grid-area: 1 / 2 / 2 / 3;
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;

      .modal-step__calendar {
        grid-area: 1 / 1 / 2 / 2;
      }

      .modal-step__blocks {
        grid-area: 2 / 1 / 3 / 2;
      }
    }
  }

  .service-calendar-footer {
    &--bordered {
      border-top: solid 1.5px #e0e0e0 !important;
    }
  }

  .rbc-calendar .rbc-btn-group button:first-child {
    display: block;
  }

  .rbc-btn-group > button {
    border: 0 !important;
    font-size: 24px !important;

    &:hover {
      background-color: #fff !important;
    }
  }
}

.modal-step {
  padding: 20px 8px 5px 24px;

  @media screen and (max-width: 380px) {
    padding: 5px;
  }

  &--bordered {
    border-bottom: solid 1.5px #e0e0e0 !important;
  }

  &--hide {
    display: none;
  }

  &--without-border {
    border-bottom: 0 !important;
  }

  &__content {
    padding-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;

    &--mh-410 {
      max-height: 410px;
      overflow-y: auto;
    }

    &--fw {
      width: calc(100vw + 5px);
      margin-left: -5px;
    }
  }

  &__title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    &--mobile {
      display: none;

      @media screen and (max-width: 800px) {
        display: block;
      }
    }
    
    &--disabled {
      color: #74788B;

      & ~ .modal-step__content {
        padding-bottom: 5px;
      }
    }

    @media screen and (max-width: 800px) {
      margin-bottom: 20px;
      display: inline-block;
    }
  }

  &__calendar {
    @media screen and (max-width: 800px) {
      height: auto;
    }
  }

  &__professional {
    width: 100%;
    max-width: 350px;

    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
  }
}

.schedule-modal-button {
  width: 355px;
  height: 56px;
  background: #74C23C;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.09);
  border-radius: 16px;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  transition: all ease .3s;
  color: #FFFFFF;

  &:hover {
    background-color: #4f8827;
    color: #FFFFFF;
  }

  @media screen and (max-width: 800px) {
    width: 355px;
    height: 40px;
    background: #74C23C;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
  }
}
