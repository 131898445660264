.link-item {
    display: block !important;
    width: 100%;
    padding: 17px;
    border-bottom: 1px solid #E8E8E8;

    &__anchor {
        display: inline-block;
        width: 100%;
        height: 100%;
        text-align: left;

        &:hover {
            text-decoration: none;
        }
    }

    &__text {
        text-align: left;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        color: #333333;
    }
}