@import '../../../styles/vars.scss';

.survey-button {
  margin-top: 40px;
  background: #74C23C;
  box-shadow: 0px 4px 8px rgba(22, 55, 121, 0.03), 0px 1px 4px rgba(22, 55, 121, 0.03);
  border-radius: 100px;

  width: 375px;
  height: 40px;
  color: #fff;
  transition: background ease 0.5s;

  &:hover {
    background: #518829;
  }

  &:disabled {
    background: rgba(22, 55, 121, 0.03);
    box-shadow: none;
    color: rgba(0, 10, 46, 0.22);
  }

  @media screen and (max-width: $mobile-size) {
    width: 311px;
  }
}