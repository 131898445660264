@import '../../../styles/vars.scss';

$up-arrow: 'data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.28973 0.70998L0.699727 5.29998C0.309727 5.68998 0.309727 6.31998 0.699727 6.70998C1.08973 7.09998 1.71973 7.09998 2.10973 6.70998L5.99973 2.82998L9.87973 6.70998C10.2697 7.09998 10.8997 7.09998 11.2897 6.70998C11.6797 6.31998 11.6797 5.68998 11.2897 5.29998L6.69973 0.70998C6.31973 0.31998 5.67973 0.31998 5.28973 0.70998Z" fill="black"/></svg>';
$down-arrow: 'data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.88047 1.29006L6.00047 5.17006L2.12047 1.29006C1.73047 0.900059 1.10047 0.900059 0.710469 1.29006C0.320469 1.68006 0.320469 2.31006 0.710469 2.70006L5.30047 7.29006C5.69047 7.68006 6.32047 7.68006 6.71047 7.29006L11.3005 2.70006C11.6905 2.31006 11.6905 1.68006 11.3005 1.29006C10.9105 0.910059 10.2705 0.900059 9.88047 1.29006Z" fill="black"/></svg>';

.service-details {
  @media screen and (max-width: $tablet-size) {
    border: 1px solid #EAEAEA;
    border-left: 0;
    border-right: 0;
  }

  &__title {
    display: block;
    padding: 15px 45px;
    border-left: solid 9px #8fca00;
    border-radius: 5px 0 0 5px;

    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #333;

    @media screen and (max-width: $tablet-size) {
      @include disable-text-selector;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      padding: 8px 0;
      border: 0;
      cursor: pointer;
      background: #fff url($down-arrow) no-repeat right 25px center;

      &--expanded {
        background: #fff url($up-arrow) no-repeat right 25px center;
      }
    }
  }
  
  &__content {
    padding: 0 45px 15px 45px;

    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    & * {
      text-align: left;
      margin: 0;
      padding: 0;
    }

    & ol {
      display: block;
      list-style: decimal;
      padding-left: 11px;
    }

    & ul {
      display: block;
      list-style: disc;
      padding-left: 11px;
    }

    & em {
      font-style: italic;
    }

    @media screen and (max-width: $tablet-size) {
      padding: 4px 16px 12px 16px;
      display: none;

      &--expanded {
        display: block;
      }
    }
  }
}
