/* BEM Methodology*/
%font-shared {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}

.service-item {
    &__image {
        height: 225px;
        max-width: 100%;
    }

    &__detail {
        padding: 15px 15px 6px 15px;
        border-top: 1px solid #f0f0f0;
        display: block !important;
        position: relative;
        height: 110px;
    }

    &__brand {
        @extend %font-shared;
        font-size: 12px;
        line-height: 14px;
        color: #767676;
        margin-bottom: 8px;
        display: block;
    }

    &__name {
        @extend %font-shared;
        font-size: 16px;
        line-height: 19px;
        color: #000;
        margin-bottom: 8px;
    }

    &__price {
        @extend %font-shared;
        font-size: 16px;
        line-height: 19px;
        color: #EF2A57;
        margin-bottom: 8px;
    }

    &__reserve {
        margin-top: 8px;
        display: none !important;
    }

    &:hover {
        .service-item__reserve {
            display: block !important;
        }
    }

    @media screen and (max-width: 767px) {
        &__brand {
            font-size: 10px;
        }

        .service-item__reserve {
            display: block !important;
        }
    }

    @media screen and (max-width: 430px) {
        &__image {
            height: 134px;
            width: 100%;
        }
    }
}
