.survey-checkbox {
  &__label {
    font-family: 'Plus Jakarta Sans';
    padding: 16px 24px;
    width: 100%;
    height: 56px;
    background: #FCFCFD;
    border: 1px solid #2E3038;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #2E3038;
    transition: all ease 0.3s;
    user-select: none;

    &--checked {
      background: #444755;
      color: #EFF0F3;
    }
  }

  &__input {
    display: none;
  }
}
