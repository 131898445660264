@import '../../../styles/vars.scss';

$up-arrow: 'data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.28973 0.70998L0.699727 5.29998C0.309727 5.68998 0.309727 6.31998 0.699727 6.70998C1.08973 7.09998 1.71973 7.09998 2.10973 6.70998L5.99973 2.82998L9.87973 6.70998C10.2697 7.09998 10.8997 7.09998 11.2897 6.70998C11.6797 6.31998 11.6797 5.68998 11.2897 5.29998L6.69973 0.70998C6.31973 0.31998 5.67973 0.31998 5.28973 0.70998Z" fill="black"/></svg>';
$down-arrow: 'data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.88047 1.29006L6.00047 5.17006L2.12047 1.29006C1.73047 0.900059 1.10047 0.900059 0.710469 1.29006C0.320469 1.68006 0.320469 2.31006 0.710469 2.70006L5.30047 7.29006C5.69047 7.68006 6.32047 7.68006 6.71047 7.29006L11.3005 2.70006C11.6905 2.31006 11.6905 1.68006 11.3005 1.29006C10.9105 0.910059 10.2705 0.900059 9.88047 1.29006Z" fill="black"/></svg>';

.cstm-select {
  & label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-top: -10px;
    margin-left: 16px;
    background-color: #fff;
    padding: 0 4px;
  }

  & .select {
    @include disable-text-selector;
    cursor: pointer;
    display: block;
    padding: 16px 12px 16px 16px;
    width: 100%;
    max-width: 327px;
    height: 56px;
    border: 2px solid #000000;
    border-radius: 8px;
    background: #fff url($down-arrow) no-repeat right 25px center;
    color: #4b4b4b;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: -18px;

    &--focus {
      background: #fff url($up-arrow) no-repeat right 25px center;
    }

    & input {
      outline: none;
      padding: 10px;
      margin-top: -10px;
      margin-left: -5px;
      max-width: 250px;
width: 90%;
    }
  }

  &__error-message {
    color: #DB5446;
    font-size: 14px;

    & img {
      margin-right: 4px;
    }

    &.hidden {
      display: none;
    }
  }
}

.select-opts {
  position: absolute;
  background-color: #fff;
  border: solid 2px #000;
  width: 327px;
  padding: 8px 0;
  z-index: 10;
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  &__item {
    @include disable-text-selector;
    display: block;
    padding: 8px 12px 8px 14px;
    cursor: pointer;
    width: 327px;
    border-left: solid 4px #FFF;
    transition: border ease 0.2s;

    &:hover {
      border-color: #96e253;
    }

    &--selected {
      border-color: #3a831a;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--hide {
    display: none;
  }
}
