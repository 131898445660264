.service-description {
  padding: 28px 0 0 5px;

  @media screen and (max-width: 800px) {
    display: none;
  }

  & span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
  }

  & div {
    margin-top: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
