.inputs__error-message {
  color: #DB5446;
  font-size: 14px;
  display: flex;

  & img {
    margin-right: 4px;
  }

  &--hidden {
    display: none;
  }
}