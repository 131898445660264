.check-form {
  &__wrapper {
    padding: 0;
  }

  &__title {
    font-size: 1.25rem;
  }

  &__item {
    margin-bottom: 40px;

    &.check {
      margin-bottom: 8px;
    }

    &--center {
      text-align: center;
    }

    &.hidden {
      display: none;
    }
  }

  &__form-btn {
    background-color: #74c23c;
    padding: 8px 12px;
    width: 327px;
    height: 40px;
    border-radius: 12px;
    color: #fff;
    $shadow-effect: 0px 4px 8px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: $shadow-effect;
    -moz-box-shadow: $shadow-effect;
    box-shadow: $shadow-effect;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    transition: all ease 0.2s;

    &:hover {
      background-color: #528603;
    }

    &:disabled {
      background-color: #8abd65;
      cursor: progress;
    }
  }
}
