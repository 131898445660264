@mixin bc-font-style {
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.breadcrumb-container {
  @media screen and (max-width: 820px) {
    background-color: #FFF !important;
  }
}

li.breadcrumb-items {
  @include bc-font-style;
  color: #000;
  height: 21px;

  &:hover {
    text-decoration: none;
  }
}

.left-link a {
  @include bc-font-style;
  color: #8f9eab !important;

  &:hover {
    text-decoration: none;
    color: #8f9eab;
  }
}

$separator: 'data:image/svg+xml;utf8,<svg width="10" height="15" viewBox="0 -5 10 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.269626 2.13504L7.78652 9.99909L0.268721 17.8631C-0.0895739 18.2381 -0.0895739 18.8446 0.268722 19.2195C0.627017 19.5935 1.20751 19.5935 1.56581 19.2195L9.73128 10.6777C10.0896 10.3037 10.0896 9.69627 9.73128 9.32229L1.56588 0.780489C1.20759 0.406504 0.626187 0.406504 0.267892 0.780489C-0.0886697 1.15353 -0.0886698 1.76106 0.269626 2.13504Z" fill="black"/></svg>';

.breadcrumb-items + .breadcrumb-items::before {
  content: url($separator);
  display: inline-block;
}

li {
  min-height: auto;
}
