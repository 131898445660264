.checkout {
  &__wrapper {
    background-color: #fff;
    padding: 15px;
    margin-top: -5px;
    text-align: center;
  }

  &-header {
    &__title {
      margin-top: 40px;
      margin-bottom: 32px;
      font-weight: 590;
      font-size: 56px;
      line-height: 64px;

      @media screen and (max-width: 800px) {
        font-size: 40px;
        margin-bottom: 24px;
        line-height: 48px;
      }
    }

    &__subtitle {
      font-weight: 590;
      font-size: 24px;
      line-height: 28px;
      max-width: 568px;
      margin: auto;

      @media screen and (max-width: 800px) {
        max-width: 590px;
      }
    }

    &__icon {
      padding-bottom: 32px;

      & > img {
        margin-top: 32px;
        width: 70px;
        height: 70px;

        @media screen and (max-width: 800px) {
          margin-top: 24px;
        }
      }
    }

    &__button {
      padding: 32px 0;

      &-info {
        font-size: 14px;
        display: block;
        margin-top: 15px;
        color: #686565;
      }
    }

    &__button > a {
      text-decoration: none;
      width: 270px;
      height: 49.87px;
      background: #a2c738;
      border-radius: 8px;
      color: #fff !important;
      padding: 14px 37px;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background-color: #7e9b2a;
        color: #fff !important;
        text-decoration: none;
      }
    }
  }

  &-experiences-list {
    &__wrapper {
      margin-top: 32px;
      padding: 0 62px;

      @media screen and (max-width: 1450px) {
        padding: 0;
      }
    }

    &__title {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;

      @media screen and (max-width: 650px) {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
      }
    }

    &__items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}

.FContainer {
  margin-top: 0 !important;
}
