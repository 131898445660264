@import '../../../styles/vars.scss';

.pdp-form {
  @media screen and (max-width: $tablet-size) {
    border-bottom: solid 1px #EAEAEA;
  }

  &--hidden {
    display: none;
  }

  &__instructions {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 20px;

    @media screen and (max-width: $tablet-size) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__item {
    margin-bottom: 28px;
  }

  &--center {
    text-align: center;
  }
}
