@import '../../styles/vars.scss';
@import '~react-big-calendar/lib/sass/styles.scss';

._loading_overlay_wrapper {
  width: 320px;

  @media screen and (max-width: 800px) {
    width: 100vw;
  }
}

.rbc-calendar {
  width: 101%;
  margin: 0 !important;
  margin-top: 30px !important;

  button.rbc-active {
    border-color: var(--falabella-green);
  }

  .rbc-month-view {
    border-radius: 5px;
  }

  .rbc-header {
    text-transform: capitalize;
    color: black;
    font-weight: normal !important;
  }

  .rbc-btn-group {
    button {
      color: black;
    }
    button:first-child {
      display: none;
    }
  }

  .rbc-month-row + .rbc-month-row {
    border-top: none;
  }

  .rbc-month-row {
    margin-bottom: 1px;
  }

  .rbc-toolbar .rbc-toolbar-label {
    text-transform: capitalize;
    font-size: 1.2em;
  }

  .rbc-button-link {
    padding-top: 10px;
  }

  .rbc-row-content {
    height: 100%;
    width: 100%;

    .rbc-row {
      height: 100%;
      width: 100%;

      .rbc-date-cell {
        padding-right: 0px;
        text-align: center;
        height: 100%;
        width: 100%;
        margin-right: 3px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: auto !important;
  }
}

.calendar-mobile {
  .rbc-header,
  .rbc-month-view,
  .rbc-date-cell.rbc-off-range {
    border: 0 !important;
  }

  .rbc-off-range {
    & button {
      cursor: default !important;
      user-select: none;
    }
  }

  .rbc-month-header {
    border-bottom: 1.5px solid #333333;
    max-width: 315px;
  }

  .selected-day-selectable,
  .available-day {
    cursor: pointer;
    border-radius: 25px;
  }
  .selected-day-selectable,
  .available-day,
  .selected-day-non-selectable {
    border-radius: 25px !important;
    height: 42px !important;
    width: 42px !important;
  }

  .rbc-calendar .rbc-row-content .rbc-row .rbc-date-cell a {
    padding-top: 0.6em !important;
  }

  .rbc-day-bg {
    border-left: 0 !important;
    margin-right: 3px;
  }

  .selected-day-non-selectable {
    cursor: not-allowed;
  }

  // Colors
  .non-available-day {
    cursor: not-allowed;
    pointer-events: none;
    background-color: #F1F1F3;
    border-radius: 50px;
  }

  .rbc-calendar .rbc-off-range-bg {
    background-color: #fff;
  }
  
  .rbc-off-range .rbc-button-link {
    color: #FFF;
  }

  .available-day {
    background-color: #eff7cf;
  }

  .rbc-calendar .available-day:not(.rbc-off-range-bg) {
    border: 1.5px solid #74C23C !important;
  }

  .selected-day-non-selectable {
    background-color: #e3f3c1;
  }

  .selected-day-selectable {
    background-color: #74C23C !important;
  }

  .without-data-button {
    color: #74788B;
  }

  .selected-button {
    color: #FCFCFD;
  }

  @media screen and (max-width: 800px) {
    .inputs__error-message  {
      padding: 0 5px;
    }
  }
}

.rbc-date-cell,
.rbc-row,
.rbc-row-content,
.rbc-month-row {
  max-height: 45px !important;
}

.rbc-header {
  max-width: 50px !important;
}
.rbc-date-cell,
.rbc-day-bg {
  max-width: 42px !important;
  min-width: 42px !important;
}

.disabled-mb-calendar {
  $disabled-color: #74788b;
  color: $disabled-color;
  
  button {
    cursor: default !important;
  }

  .rbc-header {
    color: $disabled-color;
  }

  .rbc-month-header {
    border-color: $disabled-color;
  }

  .rbc-btn-group button {
    color: $disabled-color;
  }

  .non-available-day, .rbc-now {
    background-color: #FFF !important;
  }

  @media screen and (max-width: $mobile-size) {
    display: none;
  }
}
