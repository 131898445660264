@import '../../../../styles/vars.scss';

.custom-styling-radio {
  &__wrapper {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    max-width: 184px;
    height: 350px;

    background: rgba(191, 234, 161, 0.2);
    box-shadow: 0px 4px 8px rgba(22, 55, 121, 0.03), 0px 1px 4px rgba(22, 55, 121, 0.03);
    border-radius: 16px;
    margin: 0 12px;
    padding: 8px;

    @media screen and (max-width: $mobile-size) {
      max-width: 109px;
      height: 280px;
      margin: 0 2px;
    }
  }

  &__image-wrapper {
    display: flex;
  }

  &__image {
    padding-top: 8px;
    padding-bottom: 28px;

    @media screen and (max-width: $mobile-size) {
      max-width: 56px;
      height: 182px;
    }
  }

  &__description {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #2E3038;
  }
}

.custom-control-label {
  width: 0% !important;
  cursor: pointer;
  user-select: none;
}
