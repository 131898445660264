@import '../../styles/vars.scss';

body {
  background-color: #f5f5f5;
}

.cont-inf {
  &__wrapper {
    font-family: 'Plus Jakarta Sans';
    width: 100%;
    max-width: 1100px;
    margin: auto;
    // Page height - navbar - footer
    min-height: calc(100vh - 85px - 46px);
    background-color: #FFF;

    & section {
      padding: 20px;

      @media screen and (max-width: $mobile-size) {
        & .breadcrumb-container {
          padding-bottom: 20px;
        }
      }
    }
  }

  &__details {
    display: flex;
    padding: 20px 0;

    & div {
      width: 99%;
    }

    &-separator {
      margin: auto 40px;
      max-width: 1px;
      height: 700px;
      border-left: 0.6px solid #4447558f;
      display: inline-block;
  
      @media screen and (max-width: $mobile-size) {
        display: none;
      }
    }

    @media screen and (max-width: $mobile-size) {
      flex-direction: column;

      & div.check-form__wrapper {
        margin-top: 40px;
      }
    }
  }

  &__footer {
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000;

    & img {
      width: 20px;
      height: 22px;
      margin-right: 12px;
    }

    @media screen and (min-width: $mobile-size) {
      display: none;
    }
  }
}
