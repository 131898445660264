@import '../../../../styles/vars.scss';

$font-color: #000;

.check-form-title {
  &--hidden {
    display: none;
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: $font-color;

    @media screen and (max-width: $tablet-size) {
      text-align: left;
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $font-color;
  }
}