@import '../../../styles/vars.scss';

.survey-radio {
  &__label {
    font-family: 'Plus Jakarta Sans';
    display: block;
    text-align: center;
    padding: 20px 24px;
    background: #FCFCFD;
    border: 1px solid #2E3038;
    border-radius: 100px;
    cursor: pointer;

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #2E3038;
    user-select: none;

    &--tiny {
      display: inline-block;
      width: 162.67px;
      height: 32px;
      padding: 8px 16px;
      margin: 0 4px;
    
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      @media screen and (max-width: $mobile-size) {
        width: 109px;
        height: 32px;
      }
    }

    &--checked {
      background: #444755;
      color: #EFF0F3;
    }
  }

  &__input {
    display: none;
  }
}