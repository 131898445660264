@import '../../../styles/vars.scss';

$font-gray-color: #2c2c2c;

.service-summary {
  font-family: 'Lato';

  &__wrapper {
    width: 100%;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      width: auto !important;
      height: auto !important;
      max-width: 30vw !important;
      max-height: 30vh !important;
      object-fit: fill;
      border-radius: 10px;
      object-position: center;

      @media screen and (max-width: $mobile-size) {
        width: 100% !important;
        max-width: 350px !important;
        max-height: 350px !important;
      }
    }

    &--special {
      text-align: center;

      & img {
        width: 280px;
        height: 280px;
        border-radius: 10px;
      }
    }
  }

  &__content {
    padding: 0 25px;

    @media screen and (max-width: $mobile-size) {
      padding: 0;
    }
  }

  &__brand-text {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $font-gray-color;
    display: block;

    padding-top: 36px;
  }

  &__name {
    word-break: break-word;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: $font-gray-color;
  }

  &__list {
    margin-top: 24px;
    max-width: 400px;
    color: #000;

    & h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      padding-left: 3px;
    }

    &-item {
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      &.hide {
        display: none;
      }
    }

    & label {
      width: 130px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
