@import '../../styles/vars.scss';

nav.navbar {
  background-color: #2C2C2C;
  height: 84px;
  border-bottom: solid 4px $primary-color;
  padding: 0 50px 0 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;

  & img {
    height: 84px;
  }

  & div {
    display: none;
    cursor: pointer;
  }

  & ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

    & li a, & li span {
      text-decoration: none;
      color: #FFF;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      padding-left: 24px;
      transition: all ease 0.2s;
      font-family: 'SF Pro Display', sans-serif;
      font-weight: 400;

      &:hover {
        color: $primary-color;
      }
    }
  }

  @media screen and (max-width: 600px) {
    height: 64px;
    padding: 0 27px;

    & img {
      height: 64px;
    }

    & div {
      display: block;
    }

    & ul {
      display: none;
    }
  }
}