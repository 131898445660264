@import "@fontsource/plus-jakarta-sans";

// SF Pro
@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  src: local('SF Pro Display'), url('./fonts/SFPro/SFPRODISPLAYREGULAR.woff');
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 100;
  src: local('SF Pro Display'), url('./fonts/SFPro/SFPRODISPLAYULTRALIGHTITALIC.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 200;
  src: local('SF Pro Display'), url('./fonts/SFPro/SFPRODISPLAYTHINITALIC.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 300;
  src: local('SF Pro Display'), url('./fonts/SFPro/SFPRODISPLAYLIGHTITALIC.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  src: local('SF Pro Display'), url('./fonts/SFPro/SFPRODISPLAYMEDIUM.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 600;
  src: local('SF Pro Display'), url('./fonts/SFPro/SFPRODISPLAYSEMIBOLDITALIC.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  src: local('SF Pro Display'), url('./fonts/SFPro/SFPRODISPLAYBOLD.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 800;
  src: local('SF Pro Display'), url('./fonts/SFPro/SFPRODISPLAYHEAVYITALIC.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 900;
  src: local('SF Pro Display'), url('./fonts/SFPro/SFPRODISPLAYBLACKITALIC.woff') format('woff');
}
