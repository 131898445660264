.update-appointment {
  &__wrapper {
    background-color: #FFF;
  }

  &__card {
    border: 1px solid #8FCA00;
    border-radius: 5px;
    max-width: 430px;
    max-height: 430px;
    margin: 10% auto;
    padding: 70px 16px;
    text-align: center;

    @media screen and (max-width: 450px) {
      margin: 30px 10px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  &__icon {
    margin-bottom: 20px;
  }
}

$font-family: 'Plus Jakarta Sans';

.upd-app {
  &__wrapper {
    padding: 24px 0;
    width: 100vw;
    background-color: #FFF;
    min-height: calc(100vh - 84px - 46px);

    @media screen and (max-width: 600px) {
      min-height: calc(100vh - 64px - 46px);
    }
  }

  &__title {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 64px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
  }

  &__subtitle {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #000000;
    
    &--sm {
      text-align: center;
      max-width: 853px;
      margin: 0 auto;
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__details {
    margin: 0 auto;
    max-width: 580px;
    width: 100%;

    &-title {
      & span {
        padding: 24px 24px 8px 50px;
        display: inline-block;
        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #333333;
      }
      
      border-bottom: 1px solid #F0F2F6;

      &--center {
        text-align: center;
      }
    }

    &-content {
      padding: 24px;
    }

    &-section {
      width: 50%;
      display: inline-block;
      margin-bottom: 16px;

      @media screen and (max-width: 600px) {
        width: 100%;
        display: block;
      }

      &-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #444755;
        display: block;
      }

      &-value {
        padding-top: 4px;
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #444755;

        &--capitalize {
          text-transform: capitalize;
        }
      }
    }
  }

  &-experiences-list {
    &__wrapper {
      margin-top: 32px;
      padding: 0 62px;

      @media screen and (max-width: 1450px) {
        padding: 0;
      }
    }

    &__title {
      text-align: center;
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      margin-bottom: 24px;

      @media screen and (max-width: 650px) {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
      }
    }

    &__items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 50px;
    }
  }

  &__buttons-wrapper {
    width: 300px;
    margin: 0 auto;
    text-align: center;
  }

  &__button {
    padding: 8px 16px;
    gap: 8px;
    width: 250px;
    height: 40px;
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    border-radius: 12px;
    margin-bottom: 16px;

    &--sucess {
      background: #74C23C;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.09);
      color: #FFF;
      transition: all ease .3s;

      &:hover {
        background-color: #58952D;
      }
    }

    &--cancel {
      background-color: #FFF;
      color: #2E3038;
    }
  }
}