.filter-form {
    width: 100%;
    height: 62px;
    background-color: #FFF;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__text {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: capitalize;
    }

    &__icon > img {
        height: 20px;
        padding-right: 5px;
    }
}
