.calendar-blocks {
  margin-top: 105px;

  &__section {
    margin-bottom: 10px;

    @media screen and (max-width: 800px) {
      width: 400px;
      margin: auto;
    }
  }

  &__title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    text-align: left;
  }

  @media screen and (max-width: 800px) {
    margin-top: 0;
  }
}

.block-button {
  min-width: 120px;
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #5D606F;
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 11px;
  color: #444755;

  &--selected {
    border: 2px solid #74C23C
  }

  &:focus {
    outline: none;
  }
}

.daily-blocks {
  text-align: center;

  &__title {
    text-align: left;
    margin: 19px 0;
  }
}
