:root {
  --green: #aad500;

  --gray: #d9d9d9;
  --gray-light: #BBBBBB;
  --gray-lighter: #FAFAFA;
}

@mixin itemTag {
  background-color: #eeeeee;
  border-radius: 3px;
}

.experience-item {
  &__wrapper {
    width: 311px;
    height: 330px;
    border-radius: 10px;
    padding: 15px;
    text-align: left;
    font-family: 'Lato';

    background-size: 500px;
    background-position: center;

    @media screen and (max-width: 650px) {
      width: 449px;
      height: 225px;
    }
  }

  &__body {
    height: 85%;
  }

  &__name {
    margin-bottom: 16px;
    & > span {
      @include itemTag;
      display: inline-block;
      font-size: 14px;
      padding: 8px 16px;
      font-weight: 900;
    }
  }

  &__icon {
    width: 12px !important;
    margin-right: 5px;

    &--green {
      color: #3c903e;
    }

    &--orange {
      color: #e98e27;
      transform: rotate(90deg);
    }
  }

  &__price {
    & > span {
      @include itemTag;
      padding: 5px 15px;
      font-size: 15px;
    }
  }

  &__footer {
    text-align: right;
  }

  &__button {
    text-decoration: none;
    padding: 8px 12px;
    background-color: #000;
    color: #fff;
    border-radius: 12px;

    & > span {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    &-icon {
      margin-left: 17px;
    }

    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}
