@import './fonts.scss';

* {
  padding: 0;
  margin: 0;
  font-family: Lato, sans-serif;
}

/* Custom scroll */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
