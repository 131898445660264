$primary-color: #AAD500;

$tablet-size: 820px;
$mobile-size: 580px;

@mixin disable-text-selector {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
 body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}
