.dropdown-item {
    padding: 0 !important;

    &__header {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        background-color: #FFF !important;

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: #FFF;
        }
    }

    &__title {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #000;
    }

    &__icon {
        color: #000;
    }

    &__collapse {
        background-color: #FFF !important;
        color: #000;

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: #FFF;
        }
    }
}
