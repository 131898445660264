@import '../../../styles/vars.scss';

.recommended-services {
  &__title {
    display: block;
    font-family: 'Plus Jakarta Sans';
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 26px;

    @media screen and (max-width: $tablet-size) {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }

    &:first-letter{
      text-transform: capitalize;
    }
  }

  &__list {
    display: flex;
    overflow-x: auto;
    justify-content: center;

    @media screen and (max-width: $tablet-size) {
      justify-content: left;
    }
  }

  &__item {
    margin: 0 4px;
    display: block;

    & img {
      width: 311px;
      height: 330px;
    }
  }
}
