.schedule-button {
  padding: 8px 12px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  border-radius: 12px;
  color: #fff;
  background: #74c23c;
  transition: all ease 0.5s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.09);

  &:hover {
    background: #5fa031;
  }

  &:focus {
    outline: none;
  }

  &--disabled {
    color: #8e8e8e;
    background: #f5f5f5;
    box-shadow: none;

    &:hover {
      cursor: not-allowed;
      background: #f5f5f5;
    }
  }
}
