footer.footer {
  height: 46px;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;

  & span {
    color: #FFF;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
}
