@import '../../styles/vars.scss';

.top-banner {
  &__wrapper {
    width: 100%;

    &--hidden {
      display: none;
    }
  }

  &__desktop {
    display: block;
    width: 1096px;
    max-width: 100vw;
    height: 95px;
    object-fit: cover;

    &--plp {
      width: 100%;
      height: auto;
    }
  }

  &__mobile {
    display: none;
    width: 100%;
    max-width: 100vw;
    height: 99px;
    object-fit: cover;

    &--plp {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: $mobile-size) {
    &__desktop {
      display: none;
    }

    &__mobile {
      display: block;
    }
  }
}
