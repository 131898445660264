:root {
  --green: #aad500;
  --gray: #d9d9d9;
  --gray-light: #BBBBBB;
  --gray-lighter: #FAFAFA;
}

.btn:not(.btn-dropdown-select):not(.dropdown-toggle) {
  background-color: var(--green);
  color: white;
  border-color: var(--green);

  &:hover {
    background-color: white;
    color: var(--green);
    border-color: var(--green);
  }

  &:active,
  &:focus {
    background-color: var(--green) !important;
    color: white !important;
    border-color: var(--green) !important;
  }
}

.row {
  margin-left: unset;
  margin-right: unset;
}

@media screen and (max-width: 580px) {
  .container {
    padding: 0;
  }
}