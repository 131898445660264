@import '../../styles/vars.scss';
$font-family: 'Plus Jakarta Sans', 'Roboto';

.survey-page {
  &__progress-wrapper {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 623px;

    & .progress {
      margin-top: 14px;
      width: 100%;
      height: 10px;
      background-color: #fff;

      & .progress-bar {
        background-color: #74c23c;
      }
    }

    @media screen and (max-width: $mobile-size) {
      padding: 0 24px;
    }
  }

  &__progress-text {
    padding-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    & i {
      width: 24px;
      cursor: pointer;
    }

    & span {
      font-family: $font-family;
      display: inline-block;
      width: calc(100% - 24px);
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }
  }

  &__wrapper {
    font-family: $font-family;
    padding-top: 24px;
    max-width: 380px;
    margin: auto;

    @media screen and (max-width: $mobile-size) {
      padding: 24px 15px;
    }
  }

  &__home-wrapper {
    font-family: $font-family;
    padding-top: 24px;
    max-width: 632px;
    margin: auto;
    text-align: center;

    @media screen and (max-width: $mobile-size) {
      padding: 24px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: #000000;
    text-align: center;

    @media screen and (max-width: $mobile-size) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;

    @media screen and (max-width: $mobile-size) {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__logo {
    padding-top: 70px;
    padding-bottom: 40px;
    width: 100%;
    max-width: 445px;

    @media screen and (max-width: $mobile-size) {
      max-width: 300px;
    }
  }

  &__step-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #000000;

    @media screen and (max-width: $mobile-size) {
      font-size: 20px;
      line-height: 28px;
      text-align: left;
    }
  }

  &__step-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #2e3038;

    @media screen and (max-width: $mobile-size) {
      font-size: 12px;
      line-height: 16px;
      text-align: left;
    }
  }

  &__step-information {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #2e3038;

    @media screen and (max-width: $mobile-size) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__button-wrapper {
    text-align: center;
    padding-bottom: 32px;
  }

  &__sections-wrapper {
    &.section-flex {
      display: flex;
      justify-content: space-between;
    }
  }

  &__section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #000000;
    padding: 15px 0;

    &:empty {
      padding: 0;
    }
  }

  &__section-image {
    width: 100%;
    max-width: 230px;

    @media screen and (max-width: $mobile-size) {
      max-width: 148px;
      height: 15px;
    }
  }
}

.mas-wrapper {
  background-color: #fff;
}

// Customs
.wrapper-xl {
  max-width: 650px;
}

.centered {
  text-align: center;
}
