@media only screen and (max-width: 1024px) {
  .filter-bar {
    min-height: unset;
  }

  #services-display {
    background: white;
  }

  .paginator-bar > div {
    padding: 10px 0;
  }

  .ResponsiveHeader {
    height: auto;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 1024px) {
  .md-justify-start {
    justify-content: flex-start !important;
  }

  .md-justify-end {
    justify-content: flex-end !important;
  }

  .container-fluid {
    width: 100% !important;
  }

  .view-container {
    width: 1678px !important;
    padding: 0px !important;
    padding-right: 0px !important;
    padding-right: 0px !important;
  }

  .item-container {
    width: 100%;
  }

  a.item-link {
    max-height: 248px;
    height: 18vw;
  }

  .dropdown-wrapper {
    width: auto;
  }

  .multiselect {
    margin: 12px 0 0;
    max-height: 245px;
    border-bottom: 0;
  }

  .multiselect li {
    padding: 0 0 20px;
    border-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  #services-display
    > div.paginator-bar-top.row
    > div.cc.md-justify-end.col-md-3.col-12 {
    display: none;
  }

  #h4-1 {
    display: none;
  }

  #paginator-bottom-pages {
    display: none;
  }

  .filter-bar-mobile {
    display: block;
    background-color: #f8f9fa;
    height: auto;
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .filter-bar-mobile > .dropdown {
    margin-left: 0px;
  }

  .vSeparationTop {
    margin-top: 0%;
  }

  .top-filter-container {
    padding: 0 5%;
  }

  .top-filter button {
    max-width: unset;
  }

  .filter-bar {
    display: block;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

@media (min-width: 720px) {
  .pagination {
    position: static;
  }

  ol {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  ol {
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    position: static;
  }

  .button.button-primary {
    font-weight: 700;
    background-color: #8fca00;
    color: #fff;
    height: 36px;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .ResponsiveHeader {
    display: none;
  }

  .responsiveFilter {
    flex: 0.93;
    margin: 0 auto;

    max-width: 84% !important;
    left: 4%;
    padding-bottom: 0;
  }

  .adjustPaginator {
    max-width: none !important;
    margin: 5% auto 0px;
  }

  .adjustStore {
    margin: 2% auto !important;
    max-width: 84%;
  }

  .mobileGrid {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin: 0 auto !important;
  }

  .inlinePaginator {
    margin: 2% auto;
    display: flex;
    flex-direction: row;
  }

  .colContainer {
    padding: 0px 0px 3% !important;
    display: flex;
    flex-direction: row;
    margin: 0 auto !important;
  }

  .hiddenMobile {
    display: none;
  }

  .fullWidth {
    flex: 1 !important;
    max-width: 100% !important;
  }

  .textAlignment {
    text-align: left !important;
    margin-left: 5%;
  }

  .btnReservar {
    width: 100% !important;
    min-width: 100% !important;
  }

  .VerticalItemsSeparation {
    margin-top: 10%;
  }

  /* .pictureContainer {
    display: none;
  } */

  .item-pod {
    min-height: 150px;
  }

  .gridContainer {
    grid-template-columns: 1fr;
    max-width: 90%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  #services-display
    > div.paginator-bar-top.row
    > div.cc.md-justify-end.col-md-3.col-12 {
    display: none;
  }

  .service-detail-container {
    margin-top: 10%;
  }

  .hiddenDesktop.hiddenDesktop {
    display: flex;
  }
  .hiddenMobileContainer.hiddenMobileContainer {
    display: none;
  }
  .mobile-row-service-detail {
    background-color: #ffffff;
  }
  .mobile-container-service-detail.mobile-container-service-detail {
    padding: 0;
  }

  .pictureContainer {
    height: 100%;
    flex: 1 !important;
    width: 100%;
    padding: 0px;
    margin: 0px;
    max-width: 100% !important;
    padding: 0 !important;
  }

  .colContainer {
    flex-direction: column;
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
  }

  .breadcrumb-col,
  .breadcrumb-container {
    background-color: #fafafa;
  }

  #load-structure-sku-breadcrumb,
  #load-structure-sku-breadcrumb2,
  #load-structure-sku-breadcrumb3 {
    max-width: 100%;
  }

  .btnAndContactContainer {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  .contactContainerMobile {
    margin-top: 20px;
    justify-content: center;
    padding: 20px 0px;
  }
  .item-description {
    overflow-wrap: break-word;
  }

  .shortNameMobileBreadcrumb {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Confirmation Component */
  .image-description-confirmartion-container {
    flex-direction: column;
  }
  .img-confirmation-service {
    padding: 0;
  }
  /* .img-container.img-container{
    padding: 0;
  } */

  span.orders {
    color: #000000;
  }

  .goToOrders {
    background-color: #8fca00;
    color: #ffffff !important;
    height: 50px;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .footer-message-confirmation {
    flex-direction: column;
    text-align: center;
    height: 250px;
  }

  .contact-service-confirmation {
    justify-content: center;
  }

  .confirmation-title,
  .divider-confirmation {
    display: none;
  }

  @media only screen and (max-width: 380px) {
    .paginator-bar-top > div {
      margin-right: 5rem;
    }
    /* #filter-store-img-location
    {
     padding-left: 4rem;
    } */
  }
}
