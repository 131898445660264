.service-title {
  & span {
    display: block;
  }

  &__brand {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #2C2C2C;
  }

  &__name {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: #2C2C2C;

    @media screen and (max-width: 800px) {
      line-height: 28px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__sku {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8E8E8E;
  }
}