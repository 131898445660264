@import '../../styles/vars.scss';

$phone-svg: 'data:image/svg+xml;utf8,<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.6 7.7L19.6 8.7C21.4 10.5 21.4 13.3 19.6 15.2L20.6 16.2C23.1 13.9 23.1 10.1 20.6 7.7ZM18.5 9.8L17.5 10.8C18 11.5 18 12.4 17.5 13.1L18.5 14.1C19.7 12.9 19.7 11.1 18.5 9.8ZM14.5 1H4.5C3.4 1 2.5 1.9 2.5 3V21C2.5 22.1 3.4 23 4.5 23H14.5C15.6 23 16.5 22.1 16.5 21V3C16.5 1.9 15.6 1 14.5 1ZM14.5 20H4.5V4H14.5V20Z" fill="black"/></svg>';

body {
  background-color: #f5f5f5;
}

.pdp {
  &__wrapper {
    font-family: 'Plus Jakarta Sans';
    width: 100%;
    max-width: 1100px;
    margin: auto;
    // Page height - navbar - footer
    min-height: calc(100vh - 85px - 46px);

    & section {
      &.section-card {
        background-color: #fff;

        & nav {
          padding: 24px 0 45px 26px;

          @media screen and (max-width: $tablet-size) {
            padding: 4px 16px;
          }
        }
      }

      &.section-recommended-services {
        margin-top: 18px;
        margin-bottom: 48px;

        @media screen and (max-width: $tablet-size) {
          margin: 0;
          background-color: #fff;
          padding: 36px 0 0px 16px;
        }
      }

      &.section-information {
        display: none;
        background: #fff url($phone-svg) no-repeat left 24px center;
        padding: 24px 26px;

        & span {
          margin-left: 32px;
          font-family: 'SF Pro Display', sans-serif;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
        }

        @media screen and (max-width: $tablet-size) {
          display: block;
        }
      }
    }

    & .details {
      margin-top: 32px;
      border-radius: 5px 0 0 0;

      &-mobile {
        display: none;
      }

      @media screen and (max-width: $tablet-size) {
        display: none;

        &-mobile {
          margin-top: 18px;
          display: block;
        }
      }
    }
  }

  &__service-detail {
    display: grid;
    grid-template-columns: 0.9fr 1.1fr 1px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    padding: 0 26px 48px 26px;

    @media screen and (max-width: $tablet-size) {
      padding: 0 20px;
      display: flex;
      flex-direction: column;

      &-form {
        margin-top: 16px;
      }
    }
  }

  &__separator {
    margin: auto;
    height: 90%;
    width: 0.6px;
    background-color: #4b4b4b70;

    @media screen and (max-width: $tablet-size) {
      display: none;
    }
  }
}
