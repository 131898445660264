img.service-img {
  width: 280px;
  height: 280px;
  border-radius: 10px;

  @media screen and (max-width: 850px) {
    width: 220px;
    height: 220px;
    margin: auto auto 24px auto;
  }

  @media screen and (max-width: 600px) {
    width: 163px;
    height: 163px;
    margin: auto auto 24px auto;
  }
}