.survey-tiny-checkbox {
  &__label {
    font-family: 'Plus Jakarta Sans';
    min-width: 80px;
    height: 40px;
    padding: 8px 16px;
    background: #FCFCFD;
    border: 1px solid #2E3038;
    border-radius: 100px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    transition: background ease 0.2s;
    user-select: none;
    margin: 0 4px;
    margin-bottom: 16px;

    &--checked {
      background: #444755;
      color: #EFF0F3;
    }
  }

  &__input {
    display: none;
  }
}
